.answer-container {
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
  }
  
  .que {

 
font-weight: 700;
  }
  .answer-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .links:hover{
text-decoration: none !important;

  }
 

  .answer-user {
    font-size: 18px;
    font-weight: bold;
  }
  
  .answer-text {
    font-size: 16px;
    line-height: 1.5;
  }
  